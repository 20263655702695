import './index.scss';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContextConsumer } from '../../contexts/authContext';
import { CasesContextConsumer } from '../../contexts/casesContext';
import { fireErrorToast, fireSuccessToast } from '../../utils/alert';
import { Button } from '../../components/button';
import { authLogin, submitVerifyEmail } from '../../utils/api';
import { EmailField } from '../../components/emailField';
import { TextField } from '../../components/textField';
import { useRecaptcha } from '../../hooks/useRecaptcha';

const LoginForm = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { isCaptchaChecked, renderCaptcha } = useRecaptcha();

  const { isAuthenticated, fetchedUserInfo } = AuthContextConsumer();
  const { currentCases, getCurrentCases } = CasesContextConsumer();

  const navigate = useNavigate();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const { state: locationState } = useLocation();

  const verifyEmail = async () => {
    try {
      await submitVerifyEmail({ token: query.get('token') });
      fireSuccessToast('Email confirmed');
    } catch (err) {
      fireErrorToast('Error confirming email. Please try again.');
    }
  };

  useEffect(() => {
    if (search && query.get('token')) {
      verifyEmail();
    }
  }, [search]);

  useEffect(() => {
    if (!isAuthenticated || !fetchedUserInfo) return;

    const dashboardRedirect = async () => {
      await getCurrentCases();
      if (currentCases) return navigate('/dashboard');
      navigate('/dashboard-welcome');
    };

    dashboardRedirect();
  }, [fetchedUserInfo, isAuthenticated]);

  const handleSubmit = async () => {
    if (!emailAddress || !password) {
      alert('Please enter an email and password.');
    }

    setLoading(true);
    try {
      await authLogin({ emailAddress, password });
      navigate('/verify', { state: locationState });
    } catch (err) {
      fireErrorToast('Error logging in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && isCaptchaChecked) {
      handleSubmit();
    }
  };

  return (
    <div className="login-form">
      <div className="login-form__title">Sign In</div>
      <Form validated={false}>
        <EmailField label="Email Address" value={emailAddress} onChange={setEmailAddress} placeholder="Enter email" />
        <TextField
          id="password"
          isPassword
          value={password}
          onChange={setPassword}
          onKeyDown={handleKeyDown}
          placeholder="Password"
          label="Password"
          classnames={{ input: 'margin-bottom' }}
        />

        <Form.Group className="mb-4" controlId="formBasicCheckbox">
          <span className="password-reset-link pointer" onClick={() => navigate('/forgot-password')}>
            Reset Password?
          </span>
        </Form.Group>
        {renderCaptcha()}
        {(isCaptchaChecked || process.env.NODE_ENV === 'development') && (
          <Button onClick={handleSubmit} className="w-100" color="primary" label="Login" loading={loading} />
        )}
      </Form>
      <div className="login-form__assistance-links">
        <p>
          Don't have an account yet?{' '}
          {
            <Link to="/register" className="password-reset-link">
              Create Account
            </Link>
          }
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
