import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContextConsumer } from '../../../contexts/authContext';
import { CasesContextConsumer } from '../../../contexts/casesContext';
import { AccountSettingsRow } from './accountSettingsRow';

export const SubscriptionInformation = ({ toggleCancelPlanModal, userProfile, subscriptionAmounts }) => {
  const [userCounts, setUserCounts] = useState(0);
  const [caseCounts, setCaseCounts] = useState(0);

  const navigate = useNavigate();

  const { retreiveAccountUsers } = AuthContextConsumer();
  const { getCurrentCases } = CasesContextConsumer();

  useEffect(() => {
    if (!userProfile.isAccountOwner) return;

    const fetchCounts = async () => {
      const [accountUsers, courtCases] = await Promise.all([
        retreiveAccountUsers(),
        getCurrentCases({ dependant: true }),
      ]);

      const filteredActiveCases = courtCases.filter((courtCase) => !!courtCase.active);

      setUserCounts(accountUsers.length);
      setCaseCounts(filteredActiveCases.length);
    };

    fetchCounts();
  }, []);

  const renderTrialDaysMessage = () => {
    if (userProfile.freeDaysLeft > 0)
      return `You have ${userProfile.freeDaysLeft} days left of your accounts 180 day free trial`;

    return 'You have no days left of your accounts 180 day free trial';
  };

  const showSubscriptionInformation = () => {
    if (!userProfile.isSubscribed && userProfile.isAccountOwner)
      return (
        <AccountSettingsRow
          leftCol={renderTrialDaysMessage()}
          rightCol="Upgrade Account"
          onClick={() => navigate('/subscribe')}
        />
      );
    else if (userProfile.isAccountOwner)
      return (
        <>
          <AccountSettingsRow
            leftCol="Standard Plan"
            middleCol="$20 per user month"
            rightCol="Cancel Plan"
            rightColClassName="right-col pointer"
            onClick={toggleCancelPlanModal}
          />
          <AccountSettingsRow
            leftCol="User subscription amounts"
            middleCol={subscriptionAmounts?.mainSubscriptionAmount && `$${subscriptionAmounts?.mainSubscriptionAmount}`}
            rightCol={`User Amounts X${userCounts || 0}`}
            middleColClassName="middle-col-center"
            rightColClassName="right-col-black"
          />
        </>
      );
  };

  return (
    userProfile.isAccountOwner && (
      <div className="subscription col-xs-12 col-lg-6 mb-5">
        <div className="subscription__row">
          <div className="title">Subscription</div>
        </div>
        {showSubscriptionInformation()}
      </div>
    )
  );
};
