import './index.scss';

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useNavigate } from 'react-router-dom';
import { fireErrorToast, fireSuccessToast } from '../../utils/alert';
import { AuthContextConsumer } from '../../contexts/authContext';
import { Button } from '../../components/button';
import { send2FAToken, verify2FA } from '../../utils/api';
import { twoFactorPreference } from '../../utils/constants';
import { useQueryParams } from '../../hooks/useQueryParams';

const VerificationForm = () => {
  const { pendingCaseRequestId } = useQueryParams();

  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { getUserProfile, userProfile, setIsAuthenticated } = AuthContextConsumer();
  const [last2FAMethodUsed, setLast2FAMethodUsed] = useState();

  const isVerifyCodeButtonEnabled = !loading && verificationCode?.length === 6;
  const defaultTwoFAMethod = userProfile.twoFactorPreference;
  const isSMS2FaMethodLastUsed = (last2FAMethodUsed || defaultTwoFAMethod) === twoFactorPreference.sms;

  const handleVerifyCode = async (yes) => {
    try {
      setLoading(true);
      const { redirect } = await verify2FA({ code: Number(yes || verificationCode), pendingCaseRequestId });
      setIsAuthenticated(true);
      navigate(redirect);
    } catch (err) {
      fireErrorToast('Please enter a valid verification code.');
    } finally {
      setLoading(false);
    }
  };

  const handleSend2FA = async (method) => {
    await send2FAToken({ twoFactorPreference: method });
    fireSuccessToast(`Verification code sent through ${method}`);
    setLast2FAMethodUsed(method);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const renderReSendDefaultMethod = () => (
    <span className="password-reset-link pointer" onClick={() => handleSend2FA(defaultTwoFAMethod)}>
      Send code again
    </span>
  );

  const renderSendAlternativeMethod = () => {
    const isAlternateMethodConfigured = !!userProfile.phone;
    const alternativeMethod =
      defaultTwoFAMethod === twoFactorPreference.email ? twoFactorPreference.sms : twoFactorPreference.email;

    if (!isAlternateMethodConfigured) return;

    return (
      <span className="password-reset-link pointer" onClick={() => handleSend2FA(alternativeMethod)}>
        Send code through alternative method {alternativeMethod}
      </span>
    );
  };

  return (
    <div className="verification-form">
      <h1 className="verification-form__title mb-4">Verify</h1>
      <div className="verification-label mb-2">
        Please check your {isSMS2FaMethodLastUsed ? 'mobile phone' : 'email'} and enter the 6-digit passcode
      </div>
      <ReactInputVerificationCode
        length={6}
        placeholder=""
        onChange={(value) => {
          setVerificationCode(value);
        }}
        value={verificationCode}
        autoSubmit={true}
        onCompleted={handleVerifyCode}
        inputClassName="verification-input"
      />
      <Button
        className="mb-4 verify-button"
        onClick={handleVerifyCode}
        label="Verify"
        loading={loading}
        isDisabled={!isVerifyCodeButtonEnabled}
      />
      {renderReSendDefaultMethod()}
      {renderSendAlternativeMethod()}
    </div>
  );
};

export default VerificationForm;
